<script>
    import { get_messages_for_bar } from "../db/messages";

    function loadMessage(message_id) {
        alert(message_id);
    }
</script>
<style>
 .className {
     color: #235b97;
     font-weight: bold;
 }

 .card-text {
     color: gray;
     font-size: 10pt;
 }

</style>

{#await get_messages_for_bar()}
<div class="spinner-border text-warning" role="status">
    <span class="sr-only">Loading...</span>
</div>
{:then messages}

<div class="card" style="width:100%">
    <div class="card-header">
        <table border="0" style="width:100%;">
            <tr>
                <td><i class="fad fa-envelope-open-text"></i>&nbsp;STUDENT MESSAGES</td>
                <td align="right">
                    <button type="button" class="btn badge-pill badge-success">
                        Unread Messages <span class="badge badge-light">{messages.totalMessages}</span>
                        <span class="sr-only">unread messages</span>
                    </button>
                </td>
            </tr>
        </table> 
      </div>
      <div class="card-body">
        <div class="card-deck">
            {#each messages.messages as msg, index}
                {#if index < 4}
                <div class="card" style="width: 18rem; cursor: pointer;" on:click={()=>{loadMessage(msg.id)}}>
                    <div class="card-body">
                        <div style="border-bottom: black solid 1px;">
                            <span class="className">{msg.className}</span><br/>
                            <span>{msg.studentLastName}, {msg.studentFirstName}</span>
                        </div>
                        <p class="card-text">{msg.message.message.split(' ', 10).join(' ')}...</p>
                    </div>
                </div>
                {/if}
            {/each}
        </div>
    </div>
</div>
{:catch error}
<div class="alert alert-danger">{error.message}</div>
{/await}
