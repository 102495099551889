<script>
  export let location;
  location = location;
  export let assignment_id;

  import { current_user_email } from "../auth.js";
  import { get_assignment } from "../db/general"
  import { get_student_answers } from "../db/exams";
  
  import StudentSideBar from "./StudentSideBar.svelte";
  import NormalTest from "../tests/NormalTest.svelte";
  import PerformanceTest from "../tests/PerformanceTest.svelte";

  async function fetch_test(assignment) {
    const response = await fetch("/data/" + assignment.test_name + ".json");
    return await response.json();
  }
</script>

<style>
  th,
  td {
    text-align: right;
  }
</style>

<StudentSideBar>
  {#await get_assignment(assignment_id)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then assignment}
    <!-- <pre>{JSON.stringify(assignment, null, 2)}</pre> -->
    <h2>Report: {assignment.test_name}</h2>

    {#await fetch_test(assignment)}
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    {:then test}

      {#await get_student_answers(assignment_id, current_user_email())}
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      {:then answers}
        <!-- <pre>{JSON.stringify(answers, null, 2)}</pre> -->
        <table>
          <tr>
            <th>Answered:</th>
            <td>{answers.answered}</td>
          </tr>
          <tr>
            <th>Correct:</th>
            <td>{answers.correct}</td>
          </tr>
          <tr>
            <th>Total:</th>
            <td>{answers.total}</td>
          </tr>
        </table>

        <table>
          <tr>
            <th class="p-2">#id</th>
            <!-- <th class="p-2">Completed</th> -->
            <th class="p-2">Answer</th>
            <th class="p-2">Correct</th>
          </tr>
          {#each test as question}
            <tr>
              <td class="p-2">{question.id}</td>
              <!-- <td class="p-2" style="text-align: right">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={!!answers.answers[question.id]}
                  disabled />
              </td> -->
              {#if answers.answers[question.id]}
                <td class="p-2">{answers.answers[question.id].answer}</td>
                <td class="p-2">{answers.answers[question.id].correct}</td>
              {/if}
            </tr>
          {/each}
        </table>
      {:catch error}
        <div class="alert alert-danger">{error.message}</div>
      {/await}
    {:catch error}
      <div class="alert alert-danger">{error.message}</div>
    {/await}
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}
</StudentSideBar>
