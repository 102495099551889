<script>
  export let question;
  //export let on_update;
</script>

<style>
  .answer {
    min-height: 2em;
    outline: 1px dashed var(--nice-color);
    padding: 0.5em;
  }
</style>

{@html window.render_katex(question.question)}
<br />

<div class="answer" contenteditable="true">Type your answer here</div>
