<script>
  export let class_id = 'iT63ueb4apggT0Zmjopk';
  export let activeOption;
  export let location;

  import { link, navigate } from "svelte-routing";
  import { onMount } from 'svelte';

  let activeSection = 'admin';

</script>

<style>
  .sidebar {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 300px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: scroll; /* Add vertical scrollbar */
    background-color: #89c6ff;
  }
  .content {
    
  }

  .card-header {
    background-color: #0084ff;
  }

  .btn {
    color: white;
    text-decoration: none;
  }
</style>


<div
  id="sidebarMenu"
  class="d-md-block sidebar overflow-auto border-right shadow-sm">

<div>
  <a href={'/class/' + class_id + '/manage'} use:link>Dashboard Home</a>
</div>
  
<div class="accordion" id="teacherOptions">
  <div class="card">
    <div class="card-header" style="width:100%;">
      <h2 class="mb-0" >
        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#assignments" aria-expanded="true" aria-controls="assignments">
          Assignments
        </button>
      </h2>
    </div>

    <div id="assignments" class="collapse show" aria-labelledby="assignments" data-parent="#teacherOptions">
      <div class="card-body" style="margin:0px; padding:0px;">
        <ul class="list-group">
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/manage'} use:link>Manage</a></li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/assign'} use:link>Assign</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/scores'} use:link>Scores</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Reports
        </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#teacherOptions">
      <div class="card-body" style="margin:0px; padding:0px;">
         <ul class="list-group">
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/student-data'} use:link>Student Data</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/class-data'} use:link>Class Data</a>
          </li>
        </ul>
       </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingAdministration">
      <h2 class="mb-0">
        <button class="btn btn-link btn-block text-left expanded active" type="button" data-toggle="collapse" data-target="#collapseAdministration" aria-expanded="false" aria-controls="collapseAdministration">
          Administration
        </button>
      </h2>
    </div>
    <div id="collapseAdministration" class:show={activeSection==='admin'} class="collapse" aria-labelledby="headingAdministration" data-parent="#teacherOptions">
      <div class="card-body" style="margin:0px; padding:0px;">
          <ul class="list-group">
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/settings'} use:link>Settings</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={'/class/' + class_id + '/students'} use:link>Students</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={`/asignment/buildAssignments`} use:link>Assignment Builder</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={`/asignment/messages`} use:link>Manage Student Messages</a>
          </li>
          <li class="list-group-item" style="background-color:#cddeee;">
            <a href={`/systemadmin/standards`} use:link>Standards Management</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</div>
<div class="content container" style="float:left; margin-left: 301px;">
  <slot />
</div>



