<script>
  export let question;
  export let on_update;
  let answer = {};
  function on_change(event) {
    answer[event.target.name] = event.target.value;
    on_update(question, answer);
  }
</script>

<style>
  input {
    margin: 0.5em 1em;
  }
</style>

{@html window.render_katex(question.question)}
<br />

{#each 'abcdef' as letter}
  {#if question[letter]}
    <div>
      <label class="p-row inline-p">
        {@html window.render_katex(question[letter])}
        &nbsp;
        <input
          type="text"
          name={letter}
          placeholder={letter}
          on:change={on_change} />
      </label>
    </div>
  {/if}
{/each}
