<script>
  export let location;
  location = location;
  
  import { link } from "svelte-routing";
  import ProgressWording from "../tests/ProgressWording.svelte";
  import { get_student_classes, 
          get_class_assignments_for_student } from "../db/general.js";
  import { get_single_student_assignment_results } from "../db/exams.js";
  import { formatDate } from "../db/dbUtil.js";
  import { current_user_email } from "../auth.js";

  import StudentSideBar from "./StudentSideBar.svelte";
  


</script>

<style>
  .badge {
    vertical-align: center;
  }
  .pageTitle {
    color: #10c31c;
    font-size: 16pt;
    font-weight: bold;
  }

  .assignmentTitle {
    color: #10c31c;
    text-decoration: none;
  }

  .assignmentGrid tbody td {
   
  }

  .assignmentGridHeader{
    background-color:#656d75;
    font-weight:bold;
    color: white;
    padding-left:10px;
  }

  .assignmentGridOdd {
    background-color:#efefef;
  }

  .instructions {
    margin-top:25px;
    background-color: #efefef;
    border: solid 1px #848383;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
  }

  .className {
    padding-top:15px;
    font-weight:bold;
    font-style: italic;
    color: #0465bf;
  }
</style>

<StudentSideBar>
  <div style="padding-top:10px;">
  <div class="pageTitle">My Class Assignments</div>

  {#await get_single_student_assignment_results()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then classes}

    {#each classes as cls}
        <div class="className">{cls.name}</div>
        
        <table border="0" style="width:100%;" class="assignmentGrid">
          <thead>
            <tr class="assignmentGridHeader">
              <td>
                &nbsp;Title
              </td>
              <td style="text-align: center;">
                Due Date
              </td>
              <td>
                &nbsp;Type
              </td>
              <td style="text-align:center;">
                Percentage
              </td>
              <td>
                &nbsp;Level
              </td>
            </tr>
          </thead>
          <tbody>
            {#if cls.assignments.length > 0}
              {#each cls.assignments as assignment, index}
                <tr class="{((index+1)%2) == 0 ? 'assignmentGridOdd':'' }">
                  <td>
                    <a class="assignmentTitle" href={'/assignment/' + assignment.id + '/test'} use:link>
                      &nbsp;{assignment.name}
                    </a>
                  </td>
                  <td style="text-align: center;">
                    {formatDate(assignment.dueDate)}
                    <!--<a href={'/assignment/' + assignment.id + '/report'} use:link>
                      report
                    </a>-->
                  </td>
                  <td>
                    &nbsp;{assignment.assignmentType}
                  </td>
                  <td style="text-align:center">
                    {assignment.percentageCorrect}%
                  </td>
                  <td>
                    <ProgressWording percentage={assignment.percentageCorrect}/>
                  </td>
                </tr>
              {/each}
            {:else}
              <tr>
                <td colspan=5>No Assignments</td>
              </tr>
            {/if}
          </tbody>
        </table>
  {/each}
  

  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}
  </div>
  <div>

  </div>

  <div class="instructions">
    <i class="fas fa-mouse-pointer" style="color: #0084ff;"></i> Click on the title to go to the assignment.  Click the percentage to see you assignment report.
  </div>


  <div style="position: absolute;left: 50%;-ms-transform: translate(-50%);transform: translate(-50%);">
    <table>
      <tr>
        <td style="padding-right:15px;">
          <div style="color:red; font-weight:bold">Below (Below 70%)</div>
        <td>
        <td style="padding-right:15px;">
          <div style="color:#10c31c; font-weight:bold">Met (70-79%)</div>
        </td>
        <td style="padding-right:15px;">
          <div style="color:#0c6412; font-weight:bold;">Proficient (80-89%)</div>
        </td>
        <td>
          <div style="color:#265ad0; font-weight:bold;">Exceed 90%+</div>
        </td>
      </tr>
    </table>
  </div>

        
        
        
        
</StudentSideBar>
