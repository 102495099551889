<script>
  export let question;
  export let on_update;
  
  import { onMount } from "svelte";
  import { randomizeAnswerGroup, getAnswerOptionsArray, watcher} from "../utils.js";
  
  let leftGroup;
  let rightGroup;
  let p_abcdef;
  let p_xyzvuw;
  let canvas;
  let holder;

  function qst(quest) {
    leftGroup = randomizeAnswerGroup(1, quest.answers);
    rightGroup = randomizeAnswerGroup(2, quest.answers);
    p_abcdef = getAnswerOptionsArray(leftGroup);
    p_xyzvuw = getAnswerOptionsArray(rightGroup);

    return quest;
  }

  function on_down(down_event) {
    function on_move(move_event) {}
    function on_up(event) {
      window.removeEventListener("mousemove", on_move);
      window.removeEventListener("mouseup", on_up);
    }
    window.addEventListener("mousemove", on_move);
    window.addEventListener("mouseup", on_up);
  }
  onMount(() => {
    canvas.addEventListener("mousedown", on_down);
    const ctx = canvas.getContext("2d");
    let frame;
    (function loop() {
      frame = requestAnimationFrame(loop);
      ctx.save();
      draw(ctx, canvas);
      ctx.restore();
    })();
    return () => {
      cancelAnimationFrame(frame);
      canvas.removeEventListener("mousedown", on_down);
    };
  });

  function draw(ctx, canvas) {
    canvas.width = holder.offsetWidth;
    canvas.height = holder.offsetHeight;
    ctx.globalAlpha = 0.5;
    let i = 0;
    for (const letter of p_abcdef) {
      const x = 12;
      const y = (i++ * canvas.height) / 3 + 18;
      ctx.beginPath();
      ctx.arc(x, y, 8, 0, Math.PI * 2, 0);
      ctx.fill();
    }
    i = 0;
    for (const letter of p_xyzvuw) {
      const x = canvas.width - 12;
      const y = (i++ * canvas.height) / 3 + 18;
      ctx.beginPath();
      ctx.arc(x, y, 8, 0, Math.PI * 2, 0);
      ctx.fill();
    }
    ctx.globalAlpha = 1;
    for (const letter in state) {
      if (!state[letter]) continue;
      const x0 = 12;
      const x1 = canvas.width - 12;
      let i = 0;

      for(i=0; i < p_abcdef.length; i++) {
        if(p_abcdef[i] == letter) break;
      }

      let j = 0;
      for(j=0; j < p_xyzvuw.length; j++) {
        if(p_xyzvuw[j] == state[letter]) break;
      }

      const y0 = (i * canvas.height) / 3 + 18;
      const y1 = (j * canvas.height) / 3 + 18;
      ctx.beginPath();
      ctx.moveTo(x0, y0);
      ctx.lineTo(x1, y1);
      ctx.lineWidth = 4;
      ctx.stroke();
    }
  }
  const state = {};
  let left_letter = null;
  let right_letter = null;
  function on_click(letter) {
    if (letter == left_letter) {
      left_letter = null;
      return;
    }
    if (letter == right_letter) {
      right_letter = null;
      return;
    }
    if ("abcdef".indexOf(letter) >= 0) left_letter = letter;
    if ("xyzvuw".indexOf(letter) >= 0) right_letter = letter;
    if (left_letter !== null && right_letter !== null) {
      const to_delete = new Set([left_letter]);
      for (const a_letter in state) {
        if (state[a_letter] === right_letter) {
          to_delete.add(a_letter);
        }
      }
      for (const a_letter of to_delete) {
        delete state[a_letter];
      }
      state[left_letter] = right_letter;
      left_letter = null;
      right_letter = null;
      on_update(question, normalize_state(state));
    }
  }

  function normalize_state(state) {
    const pairs = Object.keys(state)
      .map(key => key + ":" + state[key])
      .sort()
      .join("|")
      .toUpperCase();
    return pairs;
  }
</script>

<style>
  .canvas-holder {
    position: relative;
  }
  canvas {
    position: absolute;
    z-index: 0;
  }
  .option {
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 1em;
    padding: 8px;
    padding-bottom: 4px;
    z-index: 1;
  }
  .option-left {
    padding-right: 2em;
    margin-right: -2em;
  }
  .option-right {
    padding-left: 2em;
    margin-left: -2em;
  }
  .option:hover {
    border: 1px solid #0d6efd;
  }
  .option.selected {
    background: #0d6efd;
    color: white;
  }
</style>

{@html window.render_katex(qst(question).question)}
<br />
<div class="flex-row">
  <div class="flex-grow flex-col">

    {#each leftGroup as letter}
        <div
          class="flex-row flex-grow option option-left"
          style="justify-content: flex-end"
          on:click={() => on_click(letter.key)}
          class:selected={letter.key == left_letter}>
          {@html window.render_katex(letter.data)}
        </div>
    {/each}

  </div>
  <div class="canvas-holder flex-grow" bind:this={holder}>
    <canvas bind:this={canvas} />
  </div>
  <div class="flex-grow" style="z-index: 1">
    {#each rightGroup as letter}
        <div
          class="flex-row flex-grow option option-right"
          on:click={() => on_click(letter.key)}
          class:selected={letter.key == right_letter}>
          {letter.key}=>{@html window.render_katex(letter.data)}
        </div>
    {/each}
  </div>
</div>
