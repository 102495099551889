<script>
  export let location;
  location = location;

  import { onMount } from "svelte";
  import StudentSideBar from "./StudentSideBar.svelte";
  import ProgressWording from "../tests/ProgressWording.svelte";
  import {get_single_student_assignment_results} from "../db/exams";

</script>
<style>
  .title {
    font-size:18pt;
    font-weight:bold;
    color: #10c31c;
    padding-top:15px;
    padding-bottom:20px;
  }

  .reportTable thead{
    padding-top:3px;
    padding-bottom:3px;
    background-color: #efefef;
    text-align: center;
    font-size: 12pt;
    font-weight: bold;
    border-bottom: solid 1px #848383;
  }

  .classTitle {
    background-color: #afd7fc;
    font-size: 12pt;
    color: #066ccb;
    padding-left:10px;
    border-bottom: solid 1px #066ccb;
  }

  .assignmentName {
    text-decoration: none;
    color: black;
  }
  
  .assignmentName:hover {
    color: #066ccb;
  }

  .instructions {
    margin-top:20px;
    background-color: #efefef;
    border: solid 1px #848383;
    text-align: center;
    padding-top:10px;
    padding-bottom:10px;
  }
</style>

<StudentSideBar>

  <div class="title">My Reports</div>
  {#await get_single_student_assignment_results()}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then classes}

    <table border="0" style="width:100%;" class="reportTable">
      <thead>
        <tr>
          <th>Title</th>
          <th style="text-align:left;">Type</th>
          <th>Percentage</th>
          <th>Level</th>
        </tr>
      </thead>
      <tbody>
        {#each classes as cls}
        <tr>
          <td colspan="4" class="classTitle"><b>{cls.name}</b> ({cls.courseName})</td>
        </tr>
          {#each cls.assignments as assignment}
            <tr>
              <td>
                <a href=".." class="assignmentName">{assignment.name}</a>
              </td>
              <td>{assignment.assignmentType}</td>
              <td style="text-align: center;">
                {#if assignment.attempted }
                  {assignment.percentageCorrect}%
                {:else}
                  <span>N/A</span>
                {/if }
              </td>
              <td>
                <ProgressWording percentage={assignment.percentageCorrect}/>
              </td>
            </tr> 
          {/each}
        {/each}
      </tbody>
    </table>

    {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}

  <div class="instructions">
    <i class="fas fa-mouse-pointer" style="color: #0084ff;"></i> Click on the title to go to the assignment.  Click the percentage to see you assignment report.
  </div>

</StudentSideBar>
