<script>
  export let question;
  export let on_update;

  let state = {};
  function on_change(event) {
    state[event.target.name] = event.target.checked || undefined;
    on_update(question, normalize_state());
  }

  function normalize_state() {
    const parts = [];
    for (const letter of "abcdef") {
      if (question[letter]) {
        parts.push(state[letter] ? "T" : "F");
      }
    }
    return parts.join(", ").toUpperCase();
  }
</script>

<style>
  label {
    cursor: pointer;
  }
</style>

{@html window.render_katex(question.question)}
<br />

{#each question.answers as answer}
    <div class="form-check">
      <label class="form-check-label inline-p">
        <input
          class="form-check-input"
          type="checkbox"
          name={answer.key}
          on:change={on_change} />
        {@html window.render_katex(answer.data)}
      </label>
    </div>
{/each}
