<script>
  export let location;
  location = location;
  export let assignment_id;

  import { get_exam } from "../db/exams";
  import { get_assignment } from "../db/general";
  import StudentSideBar from "./StudentSideBar.svelte";
  import NormalTest from "../tests/NormalTest.svelte";
  import PerformanceTest from "../tests/PerformanceTest.svelte";

  let percent_done = 0;
  function questionChange(newPercentage) {
    percent_done = newPercentage;
  }

</script>

<style>
  .assignmentName {
    font-size:18pt;
    font-weight:bold;
    color: #10c31c;
    padding-top:15px;
  }
  .progressContainer {
    padding-top:20px;
    padding-left: 40px;
  }
</style>

<StudentSideBar>
  {#await get_assignment(assignment_id)}
    <div class="spinner-border text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  {:then assignment}
    <!-- <pre>{JSON.stringify(assignment, null, 2)}</pre> -->
    <table border="0">
      <tr>
        <td>
          <div class="assignmentName">{assignment.name}</div>
        </td>
        <td style="width:30px">&nbsp;</td>
        <td style="padding-top:20px;">
          <div class="progress" style="width:300px; height:22px; border-radius:25px;">
            <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0"
              aria-valuemin="0" aria-valuemax="100" style="width:{percent_done||0}%; border-radius: 25px; background-color:#10c31c; color:black;">
              {percent_done||0}%
            </div>
          </div>
        </td>
      </tr>
    </table>
    

    {#await get_exam(assignment.exam_id, assignment_id)}
      <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    {:then test}
      {#if test.type=='NormalTest'}
        <PerformanceTest {test} {assignment_id} />
      {:else}
        <NormalTest {test} {assignment_id} {questionChange} />
      {/if}
    {:catch error}
      <div class="alert alert-danger">{error.message}</div>
    {/await}
  {:catch error}
    <div class="alert alert-danger">{error.message}</div>
  {/await}
</StudentSideBar>
