<script>
  export let question;
  export let on_update;
  import { onMount } from "svelte";
  let canvas;
  onMount(() => {
    const ctx = canvas.getContext("2d");
    let frame;
    (function loop() {
      frame = requestAnimationFrame(loop);
      const [width, height] = [canvas.width, canvas.height];
      const step = Math.min(width, height) / 22;
      ctx.globalAlpha = 0.125;
      ctx.clearRect(0, 0, width, height);
      for (let i = 1; i <= 21; i++) {
        ctx.fillRect(0, i * step, width, 1);
        ctx.fillRect(i * step, 0, 1, width);
      }
      ctx.globalAlpha = 0.5;
      ctx.fillRect(0, height / 2, width, 1);
      ctx.fillRect(width / 2, 0, 1, height);
      ctx.beginPath();
      ctx.save();
      ctx.translate(width / 2, height / 2);
      ctx.scale(1, -1);
      ctx.beginPath();
      ctx.moveTo(width / 2, 0);
      ctx.lineTo(width / 2 - 16, -8);
      ctx.moveTo(width / 2, 0);
      ctx.lineTo(width / 2 - 16, +8);
      ctx.moveTo(0, height / 2);
      ctx.lineTo(-8, height / 2 - 16);
      ctx.moveTo(0, height / 2);
      ctx.lineTo(+8, height / 2 - 16);
      ctx.stroke();
      ctx.save();
      ctx.scale(1, -1);
      ctx.font = (step * 2) / 3 + "px monospace";
      ctx.fillStyle = "black";
      for (let i = -9; i < 10; i++) {
        ctx.fillText(i, i * step + step * 0.1, step * 0.6);
        ctx.fillText(i, -step * 0.9, -i * step - step * 0.4);
      }
      ctx.restore();
      for (const point of question.point) {
        ctx.save();
        ctx.translate(point[0] * step, point[1] * step);
        ctx.beginPath();
        ctx.arc(0, 0, 8, 0, Math.PI * 2, true);
        ctx.fillStyle = "rgba(255, 125, 0, 0.5)";
        ctx.fill();
        ctx.restore();
      }
      if (question.type === "plot-2d-lines") {
        ctx.beginPath();
        for (let i = 0; i < question.point.length; i += 2) {
          const x0 = question.point[i][0] * step;
          const y0 = question.point[i][1] * step;
          const x1 = question.point[i + 1][0] * step;
          const y1 = question.point[i + 1][1] * step;
          const dx = x1 - x0;
          const dy = y1 - y0;
          const n = 100;
          ctx.moveTo(x0, y0);
          ctx.lineTo(x0 + n * dx, y0 + n * dy);
          ctx.moveTo(x0, y0);
          ctx.lineTo(x0 - n * dx, y0 - n * dy);
        }
        ctx.lineWidth = 2;
        ctx.stroke();
      }
      ctx.restore();
    })();
    return () => {
      cancelAnimationFrame(frame);
    };
  });
  function on_down(down_event) {
    const [width, height] = [canvas.width, canvas.height];
    const step = Math.min(width, height) / 22;
    // let prev_x = down_event.clientX - down_event.target.offsetLeft - width / 2;
    // let prev_y = -(
    //   down_event.clientY -
    //   down_event.target.offsetTop -
    //   height / 2 +
    //   window.scrollY
    // );
    let prev_x = down_event.offsetX - width / 2;
    let prev_y = -(down_event.offsetY - height / 2);
    let dragi = undefined;
    let i = 0;
    for (let point of question.point) {
      const x = point[0] * step;
      const y = point[1] * step;
      const dx = x - prev_x;
      const dy = y - prev_y;
      if (dx * dx + dy * dy < 16 * 16) {
        dragi = i;
      }
      i += 1;
    }
    console.log(dragi);
    if (dragi === undefined) return;
    function on_move(move_event) {
      if (dragi == undefined) return;
      const x = move_event.clientX - move_event.target.offsetLeft - width / 2;
      const y = -(
        move_event.clientY -
        move_event.target.offsetTop -
        height / 2 +
        window.scrollY
      );
      const dx = prev_x - x;
      const dy = prev_y - y;
      prev_x = x;
      prev_y = y;
      question.point[dragi][0] -= dx / step;
      question.point[dragi][1] -= dy / step;
    }
    function on_up(event) {
      window.removeEventListener("mousemove", on_move);
      window.removeEventListener("mouseup", on_up);
      if (dragi == undefined) return;
      question.point[dragi][0] = Math.round(question.point[dragi][0] * 2) / 2;
      question.point[dragi][1] = Math.round(question.point[dragi][1] * 2) / 2;
      on_update(question, question.point);
    }
    window.addEventListener("mousemove", on_move);
    window.addEventListener("mouseup", on_up);
  }
  onMount(() => {
    canvas.addEventListener("mousedown", on_down);
    return () => {
      canvas.removeEventListener("mousedown", on_down);
    };
  });
</script>

<style>
  canvas {
    width: 480px;
    height: 480px;
    background: var(--background);
  }
</style>

{@html window.render_katex(question.question)}

<div>
  <canvas bind:this={canvas} width={480} height={480} />
</div>
